import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import BookletCard from "../../components/BookletCard";

// Components
import ContentHeader from "../../components/ContentHeader";
import Schedule from "../../components/Schedule";

export const query = graphql`
  query YearQuery($year: Int!) {
    years(year: { eq: $year }) {
      year
      color
      mootCourt {
        title
        contentHtml
        flyer {
          publicURL
        }
        banner {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              height: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { fit: COVER, cropFocus: ATTENTION }
            )
          }
        }
        jury {
          name
          company {
            name
            logo {
              childImageSharp {
                gatsbyImageData(
                  width: 140
                  height: 40
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                  transformOptions: { fit: INSIDE }
                )
              }
            }
          }
          picture {
            childImageSharp {
              gatsbyImageData(
                width: 200
                height: 200
                placeholder: BLURRED
                transformOptions: { fit: COVER }
              )
            }
          }
        }
        sponsors {
          identifier
          name
          website
          logo {
            childImageSharp {
              gatsbyImageData(
                height: 120
                width: 160
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: INSIDE }
              )
            }
          }
        }
        schedule {
          date
          times {
            begin
            end
          }
          title
          description
          link
          location {
            address
            name
            link
          }
          participants {
            defendant {
              name
              company {
                name
              }
            }
            plaintiff {
              name
              company {
                name
              }
            }
          }
        }
      }
    }
  }
`;

type MootCourtPageProps = {
  data: any;
};

class MootCourtSponsors extends React.Component<MootCourtPageProps> {
  render() {
    const { years } = this.props.data;
    const { mootCourt } = years;
    const { sponsors } = mootCourt;
    return (
      <div className="flex flex-col gap-4">
        <p className="font-semibold text-primary text-opacity-60">
          Mede mogelijk gemaakt door
        </p>
        <div className="grid sm:grid-cols-4 grid-cols-2 gap-8">
          {sponsors.map((sponsor: any) => {
            const logo = getImage(sponsor.logo);
            return (
              <a
                key={sponsor.identifier}
                href={sponsor.website}
                className="hover:opacity-80 active:opacity-50 transition-opacity"
                target="_blank"
                rel="noreferrer"
              >
                {logo ? (
                  <GatsbyImage
                    image={logo}
                    alt={sponsor.name}
                    objectFit="contain"
                    imgClassName="h-10 max-w-xs"
                  />
                ) : null}
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

class JurySection extends React.Component<MootCourtPageProps> {
  render() {
    const { years } = this.props.data;
    const { mootCourt } = years;
    const { jury } = mootCourt;
    return (
      <div className="flex flex-col gap-8">
        {mootCourt.flyer?.publicURL && (
          <BookletCard
            url={mootCourt.flyer?.publicURL}
            text={`Bekijk de flyer van de ${mootCourt.title}`}
          />
        )}
        <p className="text-2xl font-semibold text-primary text-center text-opacity-60">
          Juryleden
        </p>
        <div className="grid sm:grid-cols-4 grid-cols-2 gap-8">
          {jury.map((jury: any, index: number) => {
            const picture = getImage(jury.picture);
            return (
              <div className="flex flex-col justify-between gap-4" key={index}>
                <div className="flex flex-col justify-start h-full">
                  <p className="text-center text-sm text-primary font-black">
                    {jury.name}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-4">
                  {picture ? (
                    <GatsbyImage
                      image={picture}
                      alt={jury.name}
                      objectFit="contain"
                      imgClassName="h-36 w-36 max-w-xs"
                      imgStyle={{
                        borderRadius: "0.75rem",
                      }}
                    />
                  ) : null}
                  {jury.company.name && (
                    <p className="text-center text-sm text-primary text-opacity-60 font-bold">
                      {jury.company.name}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class MootCourtPage extends React.Component<MootCourtPageProps> {
  render() {
    const year = this.props.data.years;
    const { mootCourt } = year;

    const banner = mootCourt.banner ? getImage(mootCourt.banner) : undefined;

    return (
      mootCourt && (
        <div className="flex flex-col items-center">
          <ContentHeader
            title={mootCourt.title}
            topper={`Justitia ${year.year}`}
            background={banner}
            color={year.color}
            backLink={`/${year.year}`}
            backLabel={`Justitia ${year.year}`}
            colored_banner={true}
          />
          <div className="flex flex-col gap-20 pb-32 pt-12 px-6 w-full max-w-screen-md">
            {this.props.data.years.mootCourt.jury && (
              <JurySection data={this.props.data} />
            )}
            <p
              dangerouslySetInnerHTML={{
                __html: mootCourt.contentHtml,
              }}
              className="year-content"
            />
            {mootCourt.sponsors && <MootCourtSponsors data={this.props.data} />}
            <Schedule
              schedule={mootCourt.schedule}
              color={year.color}
              hasBanners={false}
              year={year.year}
            />
          </div>
        </div>
      )
    );
  }
}

export default MootCourtPage;
