import React from "react";

type WordBannerProps = {
  messages: string[];
  color: string;
};

export class WordBanner extends React.Component<WordBannerProps> {
  render() {
    return (
      <div
        className="w-full overflow-hidden whitespace-nowrap"
        style={{
          backgroundColor: this.props.color,
        }}
      >
        <div className="flex flex-row animate-banner">
          {[...Array(10)].map((_, i) => (
            <div className="flex">
              {this.props.messages.map((message, j) => (
                <p
                  className="text-white py-4 font-black uppercase tracking-wider text-lg flex justify-center items-center flex-none pr-12"
                  key={i}
                >
                  {message}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default WordBanner;
