import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ScheduleItem } from "../data/ScheduleItem";

// Components
import BalancingCard from "./BalancingCard";
import Link from "./Link";

type ScheduleCardProps = {
  color: string;
  item: ScheduleItem;
  hasBanners: boolean;
  year: number;
};

class ScheduleCard extends React.Component<ScheduleCardProps> {
  render() {
    const { item } = this.props;
    const mootCourtBanner =
      item.mootCourt?.banner && !item.identifier && this.props.hasBanners
        ? getImage(item.mootCourt?.banner)
        : undefined;
    const seminarBanner =
      item.seminar?.banner && item.identifier && this.props.hasBanners
        ? getImage(item.seminar?.banner)
        : undefined;
    return (
      <div className="flex flex-col gap-3 transition-transform origin-left select-none px-4 rounded-xl overflow-hidden max-w-xl w-full">
        <div className="flex flex-row items-center gap-3 -ml-8">
          <div
            className="w-3 h-3 rounded-full"
            style={{
              background: this.props.color,
            }}
          />
          <h3 className="text-base font-extrabold">
            {item.times?.begin ?? ""}
            {item.times?.end ? (
              <>
                <span className="text-opacity-70 text-sm"> t/m </span>
                {item.times.end}
              </>
            ) : null}
          </h3>
        </div>

        <div
          className={`relative flex flex-col overflow-hidden items-start gap-2 rounded-xl text-white ${
            item.participants?.defendant ||
            item.participants?.plaintiff ||
            item.items
              ? "text-primary"
              : `${
                  mootCourtBanner || seminarBanner
                    ? "sm:px-10 px-4 sm:pb-8 pb-4 sm:pt-28 pt-16"
                    : ""
                }`
          }`}
        >
          {mootCourtBanner ? (
            <div className="absolute top-0 left-0 w-full h-2/3 z-10 rounded-xl overflow-hidden">
              <GatsbyImage
                image={mootCourtBanner}
                alt="Background"
                imgClassName="object-cover h-full w-full rounded-xl"
              />
            </div>
          ) : null}
          {seminarBanner ? (
            <div className="absolute top-0 left-0 w-full h-2/3 z-10 rounded-xl overflow-hidden">
              <GatsbyImage
                image={seminarBanner}
                alt="Background"
                imgClassName="object-cover h-full w-full rounded-xl"
              />
            </div>
          ) : null}
          {seminarBanner || mootCourtBanner ? (
            <div
              className="absolute top-0 left-0 w-full h-full z-20 rounded-xl overflow-hidden"
              style={{
                background: `linear-gradient(180deg, ${this.props.color}6E 0%, ${this.props.color} 50%, ${this.props.color} 100%)`,
              }}
            />
          ) : null}
          {item.items ? (
            <div className="flex flex-col gap-2 pt-1">
              {item.items?.map((item) => {
                const speakerPicture = getImage(item.speaker?.picture);
                return (
                  <>
                    {item.item ? (
                      <>
                        <p
                          className="text-lg font-medium px-4 py-2 text-white rounded-xl mb-2"
                          style={{
                            background: item.color ?? this.props.color,
                          }}
                          key={item.item}
                        >
                          {item.item}
                        </p>
                      </>
                    ) : null}
                    {item.speaker?.name ? (
                      <div className="flex flex-row gap-4 max-w-sm items-center hover:opacity-80 active:opacity-50 transition-all">
                        {speakerPicture ? (
                          <GatsbyImage
                            image={speakerPicture}
                            alt="Speaker"
                            objectFit="cover"
                            className="w-20 h-28 flex-none rounded-lg corner-fix"
                          />
                        ) : null}
                        <div className="flex flex-col gap-2">
                          <div>
                            <p className="text-xl font-bold">
                              {item.speaker.name}
                            </p>
                          </div>
                          {item.subject ? (
                            <div>
                              <p className="text-xs font-bold opacity-50">
                                Onderwerp
                              </p>
                              <p className="text-sm">{item.subject}</p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          ) : null}
          <div className="relative z-30 flex flex-col gap-4">
            <Link to={item.link ?? ""}>
              <div
                className={`flex flex-col gap-4 ${
                  item.link
                    ? "hover:opacity-80 active:opacity-50 transition-opacity"
                    : ""
                }`}
              >
                {item.title ? (
                  <h2
                    className={`sm:text-2xl p-0 font-black ${
                      item.mootCourt?.banner || item.seminar?.banner
                        ? "text-white"
                        : "bg-primary px-4 py-2 rounded-lg text-white"
                    }`}
                  >
                    {item.title}
                  </h2>
                ) : null}
                {item.description ? (
                  <p className="text-sm">{item.description}</p>
                ) : null}
              </div>
            </Link>
            {item.participants?.plaintiff || item.participants?.defendant ? (
              <div className="flex flex-row gap-4">
                {item.participants?.plaintiff ? (
                  <BalancingCard>
                    <div className="flex flex-col bg-sky-100 px-4 py-2 rounded-xl">
                      <p className="text-sm font-bold opacity-60">Eiser</p>
                      <p className="text-lg font-semibold">
                        {item.participants.plaintiff.name}
                      </p>
                      {item.participants.plaintiff.company && (
                        <p className="text-sm opacity-60">
                          {item.participants.plaintiff.company.name}
                        </p>
                      )}
                    </div>
                  </BalancingCard>
                ) : null}
                {item.participants?.defendant ? (
                  <BalancingCard>
                    <div className="flex flex-col bg-indigo-100 px-4 py-2 rounded-xl">
                      <p className="text-sm font-bold opacity-60">Gedaagde</p>
                      <p className="text-lg font-semibold">
                        {item.participants.defendant.name}
                      </p>
                      {item.participants.defendant.company && (
                        <p className="text-sm opacity-60">
                          {item.participants.defendant.company.name}
                        </p>
                      )}
                    </div>
                  </BalancingCard>
                ) : null}
              </div>
            ) : null}
            {item.location?.name || item.link ? (
              <div className="flex flex-col md:flex-row md:gap-12 gap-4 items-end justify-between pt-4">
                {item.location?.name ? (
                  <a href={item.location.link} target="_blank" rel="noreferrer">
                    <BalancingCard>
                      <div className="flex flex-row gap-2 -ml-1 items-center rounded-lg hover:opacity-80 active:opacity-50 transition-opacity">
                        <span className="material-symbols-rounded text-2xl">
                          location_on
                        </span>
                        <div className="flex flex-col">
                          <p className="text-sm font-bold">
                            {item.location.name}
                          </p>
                          {item.location.address ? (
                            <p className="text-xs">{item.location.address}</p>
                          ) : null}
                        </div>
                      </div>
                    </BalancingCard>
                  </a>
                ) : null}
                {item.link ? (
                  <Link to={item.link}>
                    <div className="flex flex-row items-center gap-2 group transition-all hover:opacity-80 active:opacity-50">
                      <p className="group-hover:translate-x-1 transition-all">
                        Meer Info
                      </p>
                      <span className="material-symbols-rounded text-3xl group-hover:translate-x-2 transition-all">
                        arrow_forward
                      </span>
                    </div>
                  </Link>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

type ScheduleProps = {
  schedule: ScheduleItem[];
  color: string;
  hasBanners: boolean;
  year: number;
};

export class Schedule extends React.Component<ScheduleProps> {
  dateToString(date: string | Date): string {
    return new Date(date).toLocaleDateString("nl", {
      month: "long",
      day: "numeric",
      weekday: "long",
      timeZone: "UTC",
      year: "numeric",
    });
  }

  // extract all the dates from the schedule and return them as a list
  datesInSchedule(): Date[] {
    return this.props.schedule
      .reduce((acc, item) => {
        const date = new Date(item.date).toUTCString();
        if (acc.indexOf(date) === -1) {
          acc.push(date);
        }
        return acc;
      }, [] as string[])
      .map((date) => new Date(date));
  }

  // return a list of all the schedule items for a given date
  // match based on the date string
  scheduleItemsForDate(date: Date): ScheduleItem[] {
    return this.props.schedule.filter(
      (item) => new Date(item.date).toDateString() === date.toDateString()
    );
  }

  render() {
    return this.props.schedule?.length > 0 ? (
      <div className="flex flex-row justify-start">
        <div className="flex flex-col">
          <h1 className="font-bold text-4xl pb-4">Programma</h1>
          <div className="flex flex-row gap-4 justify-center">
            <div className="flex flex-row gap-4">
              <div className="w-1 block h-full bg-opacity-20 rounded-full ml-2 flex-none bg-primary" />
              <div className="flex flex-col gap-10">
                {this.datesInSchedule().map((date) => (
                  <div
                    className="flex flex-col gap-4 pt-4"
                    key={date.toDateString()}
                  >
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-row items-center gap-4 -ml-9">
                        <div className="rounded-xl flex-none flex items-center justify-center w-9 h-9 bg-primary">
                          <span className="material-symbols-rounded fill-current text-white">
                            calendar_month
                          </span>
                        </div>
                        <h2 className="text-2xl font-bold">
                          {this.dateToString(date)}
                        </h2>
                      </div>
                      {this.scheduleItemsForDate(date).map((item) => (
                        <div
                          className="flex flex-col gap-4"
                          key={JSON.stringify(item)}
                        >
                          <div className="flex flex-row gap-2 -ml-5">
                            <div className="w-1 block h-full rounded-full flex-none bg-secondary" />
                            <ScheduleCard
                              item={item}
                              color={this.props.color}
                              hasBanners={this.props.hasBanners}
                              year={this.props.year}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default Schedule;
