import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BalancingCard from "./BalancingCard";

type BookletCardProps = {
  url: string;
  color?: string;
  text: string;
};

export default class BookletCard extends React.Component<BookletCardProps> {
  render() {
    return (
      <a href={this.props.url} target="_blank" rel="noreferrer">
        <div
          className="relative rounded-xl w-full flex flex-row items-end md:gap-16 gap-4 overflow-hidden cursor-pointer hover:opacity-80 active:opacity-50 transition-opacity"
          style={{
            background: this.props.color ?? "#1f0c35",
          }}
        >
          <p className="sm:text-3xl text-base font-bold p-8 text-white">
            {this.props.text}
          </p>
          <BalancingCard amount={0.1}>
            <StaticImage
              src="../images/paper.svg"
              alt="Programmaboekje"
              className="-mb-4 mt-12 mr-12 -rotate-6 shadow-md flex-none sm:w-36 w-20"
            />
          </BalancingCard>
        </div>
      </a>
    );
  }
}
